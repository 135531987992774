exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agb-js": () => import("./../../../src/pages/AGB.js" /* webpackChunkName: "component---src-pages-agb-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/Datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/Impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-miele-js": () => import("./../../../src/pages/Miele.js" /* webpackChunkName: "component---src-pages-miele-js" */),
  "component---src-pages-neubaukuechen-js": () => import("./../../../src/pages/Neubaukuechen.js" /* webpackChunkName: "component---src-pages-neubaukuechen-js" */),
  "component---src-pages-umbaukuechen-js": () => import("./../../../src/pages/Umbaukuechen.js" /* webpackChunkName: "component---src-pages-umbaukuechen-js" */),
  "component---src-pages-wohnen-js": () => import("./../../../src/pages/Wohnen.js" /* webpackChunkName: "component---src-pages-wohnen-js" */)
}

